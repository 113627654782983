<template>
  <v-card>
    <v-card-title>Relizado (Nº de Vendas)</v-card-title>
    <highcharts :options="chartOptions"></highcharts>
  </v-card>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  name: "CardRealizado",

  props: {
    resumoVendas: {
      type: Array
    }
  },

  components: {
    highcharts: Chart
  },

  data() {
    return {
      colors: [
        "rgba(51,91,188,0.4)",
        "rgba(54,167,162,0.4)",
        "rgba(242,99,106,0.4)"
      ],
      chartOptions: {
        title: {
          text: ""
        },

        chart: {
          type: "bar",
          height: 350
        },
        xAxis: {
          categories: ["Venda"]
        },
        yAxis: {
          min: 0,
          title: {
            text: "Quantidade"
          }
        },

        series: [],
        lang: {
          noData: "Não há dados para serem apresentados"
        },
        noData: {
          style: {
            fontWeight: "normal",
            fontSize: "1rem",
            color: "rgba(0, 0, 0, 0.6)"
          }
        },

        credits: {
          enabled: false
        }
      }
    };
  },

  watch: {
    resumoVendas() {
      this.init();
    }
  },

  methods: {
    async init() {
      let obj = this.groupByVendas(this.resumoVendas);

      let arr = Object.keys(obj).map(function(key) {
        return [key, obj[key]];
      });

      for (let i = 0; i < arr.length; i++) {
        this.chartOptions.series.push({
          name: arr[i][0],
          data: [arr[i][1]],
          color: this.colors[i]
        });
      }
      // this.chartOptions.series = {
      //   name: null,
      //   data: Object.keys(obj).map(function(key) {
      //     return [key, obj[key]];
      //   })
      // };
    },

    groupByVendas(array) {
      return array.reduce((result, currentValue) => {
        if (currentValue.descricao_venda == "CDC/AV/AP") {
          result["novos"] =
            result["novos"] + parseInt(currentValue.qtde_realizado) ||
            parseInt(currentValue.qtde_realizado);
        }

        if (currentValue.descricao_venda == "USADOS") {
          result["usados"] =
            result["usados"] + parseInt(currentValue.qtde_realizado) ||
            parseInt(currentValue.qtde_realizado);
        }

        if (currentValue.descricao_venda == "COTAS") {
          result["consorcio"] =
            result["consorcio"] + parseInt(currentValue.qtde_realizado) ||
            parseInt(currentValue.qtde_realizado);
        }
        return result;
      }, {});
    }
  },

  mounted() {
    this.init();
  }
};
</script>

<style lang="scss"></style>
